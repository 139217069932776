'use client'

import { useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter, useSearchParams } from 'next/navigation'
import { useRecoilState } from 'recoil'
import { userState, siteState } from '@atoms/index'

import SiteLogo from '@components/site-logo'
import { APIUserLogin } from '@lib/services/apiService'
import { SiteDataType } from '@lib/types/siteData.types'
import {
    API_USER_FORGOT_PASSWORD,
    API_USER_FORGOT_PASSWORD_RESET,
    API_USER_FORGOT_PASSWORD_VALIDATE,
} from '@lib/api-routes'

const LoginScreen = () => {
    const [siteData] = useRecoilState<SiteDataType | null | any>(siteState)
    const [userData, setUserData] = useRecoilState<any>(userState)

    const [view, setView] =
        useState<'login' | 'forgot' | 'validate' | 'reset'>('login')
    const [, setUserResponse] = useState<any>(null)
    const [loading, setLoading] = useState(false)
    const [emailAddress, setEmailAddress] = useState()
    const [resetCode, setResetCode] = useState<any>(null)
    const [error, setError] = useState<any>(false)

    const router = useRouter()
    const searchParams = useSearchParams()

    const verified = searchParams.get('verified')

    useEffect(() => {
        setResetCode(null)
        if (userData) {
            router.push('/dashboard')
        }
    }, [])

    const onChange = () => {
        setError(false)
    }

    const onSubmitLogin = async (e: any) => {
        e.preventDefault()
        setLoading(true)
        const body = {
            email: e.target.email.value,
            password: e.target.password.value,
            siteId: siteData?.id,
        }
        //@ts-ignore
        const { success, error, response } = await APIUserLogin(body)

        if (success) {
            setUserData(response.data)
            localStorage.setItem('token', response.token)
            localStorage.setItem('tokenType', 'user')
            router.push('/')
        } else {
            setError(error)
            console.error(error)
        }

        setLoading(false)
    }

    const onSubmitForgotPassword = (e) => {
        e.preventDefault()
        setLoading(true)
        setUserResponse(null)
        const userEmail = e.target.email.value

        fetch(`${process.env.API_URL}${API_USER_FORGOT_PASSWORD}`, {
            method: 'POST',
            body: JSON.stringify({ email: userEmail }),
        })
            .then((response) => response.json())
            .then((response) => {
                setView('validate')
                setLoading(false)
                setEmailAddress(userEmail)
            })
            .catch((error) => {
                console.error(error)
                onError(error)
            })
    }

    const onSubmitValidateCode = (e) => {
        e.preventDefault()
        setLoading(true)
        setUserResponse(null)
        const formData = {
            email: emailAddress,
            code: e.target.code.value,
        }
        fetch(`${process.env.API_URL}${API_USER_FORGOT_PASSWORD_VALIDATE}`, {
            method: 'POST',
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((response) => {
                setView('reset')
                setLoading(false)
                setResetCode(formData.code)
            })
            .catch((error) => {
                console.error(error)
                onError(error)
            })
    }

    const onSubmitResetPassword = (e) => {
        e.preventDefault()
        setLoading(true)
        setUserResponse(null)
        const formData = {
            email: emailAddress,
            password: e.target.password.value,
            confirmPassword: e.target.confirmpassword.value,
        }

        if (formData.password !== formData.confirmPassword) {
            setUserResponse({
                type: 'error',
                content: 'Passwords must match!',
                dismissable: false,
            })
            setLoading(false)
            return false
        }

        fetch(`${process.env.API_URL}${API_USER_FORGOT_PASSWORD_RESET}`, {
            method: 'POST',
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((response) => {
                setView('login')
                setLoading(false)
                setUserResponse({
                    type: 'success',
                    content: response.data.message,
                    dismissable: false,
                })
            })
            .catch((error) => {
                console.error(error)
                onError(error)
            })
    }

    const onError = (error) => {
        console.warn(error)

        if (
            error.response?.status === 400 ||
            error.response?.status === 403 ||
            error.response?.status === 404
        ) {
            localStorage.clear()
            setUserResponse({
                type: 'error',
                content: error.response.data.message,
                dismissable: false,
            })
        }
        setLoading(false)
    }

    const renderLogin = () => {
        return (
            <form onSubmit={onSubmitLogin} className="mb-10">
                <div className="mb-8">
                    <div className="text-sm font-bold text-gray-700 tracking-wide">
                        Email
                    </div>
                    <input
                        className={`w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-${siteData.design.theme}-500`}
                        type="text"
                        placeholder="Enter your email"
                        name="email"
                        required
                        onChange={onChange}
                    />
                </div>
                <div>
                    <div className="flex justify-between items-center">
                        <div className="text-sm font-bold text-gray-700 tracking-wide">
                            Password
                        </div>
                        {
                            <div>
                                <button
                                    type="button"
                                    className={`text-xs font-display font-semibold text-${siteData.design.theme}-600 hover:text-${siteData.design.theme}-800 cursor-pointer`}
                                    onClick={() => setView('forgot')}
                                >
                                    Forgot Password?
                                </button>
                            </div>
                        }
                    </div>
                    <input
                        className={`w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-${siteData.design.theme}-500`}
                        type="password"
                        placeholder="Enter your password"
                        name="password"
                        required
                        onChange={onChange}
                    />
                </div>
                <div className="mt-10">
                    <button
                        type="submit"
                        className={`bg-${siteData.design.theme}-600 text-white p-4 w-full rounded-full tracking-wide font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-${siteData.design.theme}-700 shadow-lg`}
                        disabled={loading}
                    >
                        {!loading ? 'Log in' : 'Please wait...'}
                    </button>
                </div>
                {error && (
                    <div
                        className="text-red-500 mt-10"
                        dangerouslySetInnerHTML={{
                            __html: error,
                        }}
                    ></div>
                )}
            </form>
        )
    }

    const renderForgotPassword = () => {
        return (
            <form onSubmit={onSubmitForgotPassword} className="mb-10">
                <div>
                    <div className="text-sm font-bold text-gray-700 tracking-wide">
                        Email Address
                    </div>
                    <input
                        className={`w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-${siteData.design.theme}-500`}
                        type="text"
                        placeholder="Enter your email address"
                        name="email"
                        required
                    />
                </div>
                <div className="mt-10">
                    <button
                        type="submit"
                        className={`bg-${siteData.design.theme}-600 text-white p-4 w-full rounded-full tracking-wide font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-${siteData.design.theme}-700 shadow-lg`}
                        disabled={loading}
                    >
                        {!loading ? 'Request reset code' : 'Please wait...'}
                    </button>
                </div>
            </form>
        )
    }

    const renderValidateCode = () => {
        return (
            <form onSubmit={onSubmitValidateCode} className="mb-10">
                <input type="hidden" name="email" defaultValue={emailAddress} />
                <div>
                    <div className="text-sm font-bold text-gray-700 tracking-wide">
                        Your Code
                    </div>
                    <input
                        className={`w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-${siteData.design.theme}-500`}
                        type="text"
                        placeholder="1234"
                        name="code"
                        required
                    />
                </div>
                <div className="mt-10">
                    <button
                        type="submit"
                        className={`bg-${siteData.design.theme}-600 text-white p-4 w-full rounded-full tracking-wide font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-${siteData.design.theme}-700 shadow-lg`}
                        disabled={loading}
                    >
                        {!loading ? 'Validate code' : 'Please wait...'}
                    </button>
                </div>
            </form>
        )
    }

    const renderResetPassword = () => {
        return (
            <form onSubmit={onSubmitResetPassword} className="mb-10">
                <input type="hidden" name="email" defaultValue={emailAddress} />
                <input type="hidden" name="code" defaultValue={resetCode} />
                <div className="mt-8">
                    <div className="text-sm font-bold text-gray-700 tracking-wide">
                        New Password
                    </div>
                    <input
                        className={`w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-${siteData.design.theme}-500`}
                        type="password"
                        placeholder="Your new password"
                        name="password"
                    />
                </div>
                <div className="mt-8">
                    <div className="text-sm font-bold text-gray-700 tracking-wide">
                        Confirm New Password
                    </div>
                    <input
                        className={`w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-${siteData.design.theme}-500`}
                        type="password"
                        placeholder="Confirm new password"
                        name="confirmpassword"
                    />
                </div>
                <div className="mt-10">
                    <button
                        type="submit"
                        className={`bg-${siteData.design.theme}-600 text-white p-4 w-full rounded-full tracking-wide font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-${siteData.design.theme}-700 shadow-lg`}
                        disabled={loading}
                    >
                        {!loading ? 'Set new password' : 'Please wait...'}
                    </button>
                </div>
            </form>
        )
    }

    const renderImage = () => {
        const images = [
            'https://images.unsplash.com/photo-1501901609772-df0848060b33?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2100&q=80',
        ]
        const random = Math.floor(Math.random() * images.length)
        return (
            <img
                className="object-cover h-full w-full"
                src={images[random]}
                alt=""
            />
        )
    }

    return (
        <div className="lg:flex">
            <div className="lg:w-1/2 xl:max-w-screen-sm">
                <div
                    className={`py-6 bg-${siteData.design.theme}-100 lg:bg-white flex justify-center lg:justify-start lg:px-12`}
                >
                    <SiteLogo type="dark" />
                </div>
                <div className="mt-4 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-8 xl:px-24 xl:max-w-2xl">
                    <h2 className="text-center text-4xl text-grey-900 font-display font-semibold lg:text-left xl:text-5xl xl:text-bold mb-12">
                        {view === 'login' && 'Log in'}
                        {view === 'forgot' && 'Forgot password'}
                        {view === 'validate' && 'Validate code'}
                        {view === 'reset' && 'New password'}
                    </h2>

                    {verified && <p className="mb-4 -mt-4 text-green-500 rounded">Email verified, please login!</p>}
                    {view === 'validate' && (
                        <p>
                            If that email address is registered on our site a
                            reset code will be sent to it shortly. Codes will
                            expire after 1 hour.
                        </p>
                    )}
                    {view === 'login' && renderLogin()}
                    {view === 'forgot' && renderForgotPassword()}
                    {view === 'validate' && renderValidateCode()}
                    {view === 'reset' && renderResetPassword()}
                    <div className="text-sm font-display font-semibold text-gray-700 text-center">
                        Don&apos;t have an account?{' '}
                        <Link
                            href="/register"
                            className={`cursor-pointer text-${siteData.design.theme}-600 hover:text-${siteData.design.theme}-800`}
                        >
                            Sign up
                        </Link>
                    </div>
                </div>
            </div>
            <div className="hidden lg:flex items-center justify-center bg-gray-100 flex-1 h-screen">
                {renderImage()}
            </div>
        </div>
    )
}

export default LoginScreen